import React from 'react';

import { withCollapse } from '../Collapseable';
import DropArrow from '../DropArrow';
import { CenteredSpinner } from '../Spinner/Spinner';
import RichText from '../../containers/RichText';

import './ToggleableText.scss';

interface IToggleableText {
  shownText: string,
  hiddenText: string,
  collapsed: boolean,
  collapse: Function,
  async: boolean,
  asyncFetch: Function
}

const ToggleableText: React.FC<IToggleableText> = ({
  shownText,
  hiddenText,
  collapsed,
  collapse,
  async = false,
  asyncFetch
}) => {

  const Direction = {
    UP: 'up',
    DOWN: 'down'
  }

  const fetchAndCollapse = () => {
    collapse();
    if (!hiddenText) {
      asyncFetch();
    }
  };

  let condFunc: any;
  condFunc = async ? fetchAndCollapse : collapse;

  return (
    <div className="c-toggleable-text">
      <div className="c-toggleable-text__heading" onClick={condFunc}>
        {shownText}
        <div className="c-toggleable-text__indicator">
          <DropArrow direction={collapsed ? Direction.DOWN : Direction.UP} />
        </div>
      </div>

      {!collapsed && (
        <div className="c-toggleable-text__description">
          {async && !hiddenText && <CenteredSpinner dark />}
          <RichText text={hiddenText} />
        </div>
      )}
    </div>
  );
};

export default withCollapse(ToggleableText, true);
