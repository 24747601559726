import React, { Fragment } from 'react';
import cs from 'classnames';
import { RichText } from '../../../containers/RichText';

import './RecipeDetails.scss';
import { RecipeIngredient } from '../../../types/xim/recipe';

interface IngredientsGroupIProps {
  headline?: string | null;
  ingredients: RecipeIngredient[];
  multiplyFactor: number;
}

const IngredientsGroup: React.FC<IngredientsGroupIProps> = ({
  headline,
  ingredients,
  multiplyFactor
}) => (
  <Fragment>
    {headline && <h3>{headline}</h3>}
    <ul>
      {ingredients.map((ingredient, index) => (
        <li key={index}>
          {`
            ${
              ingredient.unit
                ? (multiplyFactor * ingredient.quantity)
                    .toFixed(2)
                    .replace(/[.,](00)|(0)$/, '')
                : ''
            }
            ${ingredient.unit ? ingredient.unit : ''}
            ${ingredient.name}
          `}
        </li>
      ))}
    </ul>
  </Fragment>
);

interface RecipeDetailsIProps {
  ingredients: RecipeIngredient[];
  multiplyFactor: number;
}

const RecipeDetails: React.FC<RecipeDetailsIProps> = ({
  ingredients,
  multiplyFactor
}) => {
  const groupedIngredients: { [key: string]: RecipeIngredient[] } =
    ingredients &&
    ingredients
      .sort((a, b) => {
        const vAGroupId = !a.groupId ? '' : a.groupId,
          vBGroupId = !b.groupId ? '' : b.groupId;

        const vAIngredientId = !a.ingredientId ? '' : a.ingredientId,
          vBIngredientId = !b.ingredientId ? '' : b.ingredientId;

        return (
          vBGroupId.localeCompare(vAGroupId) &&
          vBIngredientId.localeCompare(vAIngredientId)
        );
      })
      .reduce((acc, obj) => {
        const key =
          !obj['groupId'] || obj['groupId'] === '' ? '0' : obj['groupId'];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

  return (
    <div className="c-recipe-details">
      <div className={'c-recipe-details__title'}>
        <h3>Ingredienser</h3>
      </div>
      <div className={cs('c-recipe-details__ingredients')}>
        <div className={'c-recipe-details__dropdown-wrapper'}>
          {groupedIngredients && (
            <RichText>
              {Object.keys(groupedIngredients).map((key, index) => (
                <IngredientsGroup
                  key={index}
                  ingredients={groupedIngredients[key]}
                  headline={groupedIngredients[key][0].groupName}
                  multiplyFactor={multiplyFactor}
                />
              ))}
            </RichText>
          )}
        </div>
      </div>
    </div>
  );
};

RecipeDetails.defaultProps = {
  multiplyFactor: 1
};

export default RecipeDetails;
