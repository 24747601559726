import React from 'react';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { getNextAvailabilityDateForStore } from '../../lib/availability/availability';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { AvailabilityImage } from './AvailabilityImage';
import AvailabilityText from './AvailabilityText';
import { IAvailabilityProps } from './availability.interface';
import './Availability.scss';

const Availability: React.FC<IAvailabilityProps> = ({ availability }) => {
  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );

  const campaign = getNextAvailabilityDateForStore(availability, storeNumber);

  if (!campaign) {
    return null;
  }

  return (
    <div className="c-availability">
      {campaign.availableFrom || campaign.availableTo || campaign.expired ? (
        <AvailabilityImage />
      ) : null}
      <AvailabilityText campaign={campaign} />
    </div>
  );
};

export default Availability;
