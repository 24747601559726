import React from 'react';
import * as ga4 from '@citygross/analytics';
import ProductList from '../../../components/ProductList';

const impressionListName = 'Searchresult products';

class ProductView extends React.PureComponent {
  componentDidMount() {
    const { items } = this.props;
    // runs when products gets updated
    if (!items || !items.length > 0) {
      return;
    }

    try {
      const ga4ImpressionItems = items.map((prod, index) => {
        const priceInfo =
          prod.prices?.find(x => x.storeNumber === this.props.storeId) ||
          prod.prices[0];

        const unitPrice = priceInfo.ordinaryPrice.price || 0;
        let quantity = 1;
        let discount = 0;

        if (priceInfo.hasPromotion) {
          const promo = priceInfo.promotions2[0];
          quantity = promo?.minQuantity || 1;
          discount =
            quantity > 1
              ? unitPrice * quantity - (promo.value || 0)
              : unitPrice - (promo.value || 0);
        }

        return {
          item_id: prod.gtin,
          item_name: prod.name,
          item_brand: prod.brand || 'City Gross',
          item_category:
            prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) || undefined,
          item_variant: prod.variant || undefined,

          item_list_name: impressionListName,
          item_list_id: impressionListName,
          index: index + 1,

          price: unitPrice,
          quantity: quantity,
          discount: discount
        };
      });

      ga4.viewItemList({
        items: ga4ImpressionItems,
        item_list_id: impressionListName,
        item_list_name: impressionListName
      });
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (items && prevProps.items.length !== this.props.items.length) {
      try {
        const ga4ImpressionItems = items.map((prod, index) => {
          const priceInfo =
            prod.prices?.find(x => x.storeNumber === this.props.storeId) ||
            prod.prices[0];

          const unitPrice = priceInfo.ordinaryPrice.price || 0;
          let quantity = 1;
          let discount = 0;

          if (priceInfo.hasPromotion) {
            const promo = priceInfo.promotions2[0];
            quantity = promo?.minQuantity || 1;
            discount =
              quantity > 1
                ? unitPrice * quantity - (promo.value || 0)
                : unitPrice - (promo.value || 0);
          }

          return {
            item_id: prod.gtin,
            item_name: prod.name,
            item_brand: prod.brand || 'City Gross',
            item_category:
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              undefined,
            item_variant: prod.variant || undefined,

            item_list_name: impressionListName,
            item_list_id: impressionListName,
            index: index + 1,

            price: unitPrice,
            quantity: quantity,
            discount: discount
          };
        });

        ga4.viewItemList({
          items: ga4ImpressionItems,
          item_list_id: impressionListName,
          item_list_name: impressionListName
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const {
      items,
      pagination,
      requestMoreProducts,
      fetching,
      addToCart,
      setProductFavorite,
      removeFavorite,
      increaseItemQuantity,
      decreaseItemQuantity,
      isAuthenticated,
      redirectToLogin
    } = this.props;

    const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;
    const setProductFavoriteFn = isAuthenticated
      ? setProductFavorite
      : redirectToLogin;
    const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

    return (
      <ProductList
        fetching={fetching}
        addToCart={addToCartFn}
        setProductFavorite={setProductFavoriteFn}
        removeFavorite={removeFavoriteFn}
        increaseItemQuantity={increaseItemQuantity}
        decreaseItemQuantity={decreaseItemQuantity}
        requestMoreProducts={requestMoreProducts}
        pagination={pagination}
        items={items}
        impressionListName={impressionListName}
        itemsPerPage={20}
      />
    );
  }
}
export default ProductView;
