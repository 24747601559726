import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { userPropType } from '../../prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { selectedStore } from '../../selectors/assortments';
import { toggleModalActive } from '../../actions/assortments';
import MediaQuery from 'react-responsive';
import FaqModule from '../../components/FaqModule';
import ContactForm from './ContactForm';
import ComplaintForm from './ComplaintForm';
import Select from '../../components/Select';
import { Button } from '../../components/Buttons';
import { ContactCards, ContactCard } from '../../components/ContactCard';
import { CGTabs, TabPanel, TabList, Tab } from '../../components/TabbedContent';
import RichText from '../../containers/RichText';
import ErrorInfo from '../../components/ErrorInfo';
import { CenteredSpinner } from '../../components/Spinner/Spinner';
import ContentBlocksArea from '../../components/ContentBlocksArea';

import './CustomerService.scss';

const CustomerService = ({
  authenticated,
  user,
  onFormCsSubmit,
  onFormCpSubmit,
  getPageContent,
  formLoading,
  csSent,
  cpSent,
  formError,
  pageError,
  page,
  settings,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state: AppState) => selectedStore(state));

  const [selectedContentPart, setSelectedContentPart] = useState(
    'questionsAndAnswers'
  );

  const renderContactUs = () => {
    return authenticated && !user.firstName ? (
      <CenteredSpinner dark />
    ) : (
        <ContactForm
          onSubmit={onFormCsSubmit}
          formLoading={formLoading}
          user={user}
          sent={csSent}
          formError={formError}
        />
      );
  };

  const renderComplaint = () => {
    return authenticated && !user.firstName ? (
      <CenteredSpinner dark />
    ) : (
        <ComplaintForm
          onSubmit={onFormCpSubmit}
          formLoading={formLoading}
          user={user}
          sent={cpSent}
          formError={formError}
        />
      );
  };

  return (
    <div className="c-customerservice">
      {pageError ? (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={getPageContent}
        />
      ) : (
          <Fragment>
            <h1 className="h1 display-xs-1">
              {page && page.headline && page.headline}
            </h1>
            <div className="c-customerservice__cards">
              <ContactCards>

                {page.serviceInfo && (
                  <ContactCard
                    title={page.serviceInfo.title && page.serviceInfo.title}
                    phonenumber={
                      settings && settings.phoneEmail && settings.phoneEmail.phone
                    }
                    mailadress={
                      settings && settings.phoneEmail && settings.phoneEmail.email
                    }
                  >
                    {page.serviceInfo.text && (
                      <RichText text={page.serviceInfo.text} />
                    )}
                  </ContactCard>
                )}

                {store ? (
                  <ContactCard
                    title={store.name}
                    icon="cart"
                    phonenumber={
                      store.populated &&
                      store.populated.contactInformation.phone
                    }
                    mailadress={
                      store.populated &&
                      store.populated.contactInformation.email
                    }
                  />
                ) : (
                    page.noStore && (
                      <ContactCard
                        title={page.noStore.title && page.noStore.title}
                        icon="cart"
                      >
                        {page.noStore.text && <RichText text={page.noStore.text} />}
                        <Button
                          className={'mt-10 full-width-xs'}
                          primary
                          onClick={() => dispatch(toggleModalActive(true))}
                        >
                          {page.noStore.linkText && page.noStore.linkText}
                        </Button>
                      </ContactCard>
                    )
                  )}
              </ContactCards>
            </div>
          </Fragment>
        )
      }
      
      <div className="c-customerservice__blocks">
        {page?.contentBlocks?.length > 0 && <ContentBlocksArea blocks={page.contentBlocks} />}
      </div>

      <MediaQuery maxWidth={767}>
        <div className="c-customerservice__picker">
          <div className="mb-20">
            <Select
              onChange={setSelectedContentPart}
              options={[
                { value: 'questionsAndAnswers', label: 'Frågor och svar' },
                { value: 'contactUs', label: 'Kontakta oss' },
                { value: 'complaint', label: 'Reklamera' }
              ]}
            />
          </div>
          {selectedContentPart === 'questionsAndAnswers' && <FaqModule />}
          {selectedContentPart === 'contactUs' && renderContactUs()}
          {selectedContentPart === 'complaint' && renderComplaint()}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <div className="c-customerservice__tabs">
          <CGTabs
            className="c-tabbedcontent--plain"
            selectedTabClassName="active"
            selectedTabPanelClassName="active"
            forceRenderTabPanel
          >
            <TabList className="tabs">
              <Tab className="tab">Frågor &amp; svar</Tab>
              <Tab className="tab">Kontakta oss</Tab>
              <Tab className="tab">Reklamera</Tab>
            </TabList>
            <TabPanel className="content">
              <FaqModule />
            </TabPanel>
            <TabPanel className="content">{renderContactUs()}</TabPanel>
            <TabPanel className="content">{renderComplaint()}</TabPanel>
          </CGTabs>
        </div>
      </MediaQuery>
    </div >
  );
};

CustomerService.propTypes = {
  page: PropTypes.shape({
    contentblocks: PropTypes.array
  }),
  user: userPropType,
  authenticated: PropTypes.bool,
  settings: PropTypes.shape({
    faqCats: PropTypes.array,
    productMarkings: PropTypes.array,
    recipeMarkings: PropTypes.array
  }),
  favoriteStore: PropTypes.any,
  onFormCsSubmit: PropTypes.func,
  onFormCpSubmit: PropTypes.func,
  csSent: PropTypes.bool,
  cpSent: PropTypes.bool,
  formLoading: PropTypes.bool,
  formError: PropTypes.any,
  pageError: PropTypes.bool,
  getPageContent: PropTypes.func
};

export default CustomerService;
