import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../selectors/user';
import { submitForm } from '../../api/endpoints/app';
import RichText from '../../containers/RichText';
import Form, {
  FormItem,
  SubmitButton,
  ErrorMessage,
  Row,
  Column
} from '../../components/Form';
import { IFormBlockProps, IFormFieldVariants } from '../../types/episerver/blocks/FormBlock';
import { AppState } from '../../reducers';

const mapToFormItem = (item: IFormFieldVariants) => {
  switch (item.type) {
    case 'TextFieldBlock': {
      return (
        <FormItem
          type={item.textFieldType}
          label={item.title}
          id={item.id}
          required={item.required}
          showRequiredInfo={item.required}
          placeholder={item.placeHolder}
        />
      );
    }
    case 'SponsorsBlock': {
      return (
        <FormItem
          type="select"
          options={item.sponsorItems.map(o=> {
            return{
              value : o.Value,
              label : o.Text
            }
          })
        }
          label={item.title}
          id={item.id}
          required={item.required}
          showRequiredInfo={item.required}
          placeholder={item.placeHolder}
          defaultValue={item.sponsorItems[0].Value}
        />
      );
    }
    case 'CheckboxFieldBlock': {
      return (
        <FormItem
          type="checkbox"
          id={item.id}
          label={item.title}
          validationHint={item.validationText}
          required={item.required}
          showRequiredInfo={item.required}
        >
          {item.xhtmlTitle && (
            <span dangerouslySetInnerHTML={{ __html: item.xhtmlTitle }} />
          )}
        </FormItem>
      );
    }
  }
};

const FormBlock : React.FC<IFormBlockProps> = ({
  formId,
  formFieldsContentArea,
  submitLabel,
  successText
}) => {
  const user = useSelector((state: AppState) => userSelector(state));

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = (data: any, reCaptcha: string) => {
    const formData = {
      formId: formId,
      fieldsData: Object.entries(data).map(item => {
        return {
          id: item[0],
          value: item[1]
        };
      }),
      customerId: user.kdbId
    };

    setLoading(true);
    submitForm(formData, reCaptcha)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (success) return <RichText text={successText} />;

  return (
    <Form
      onSubmit={onSubmit}
      loading={loading}
      errorList={error && ['Något gick fel']}
      useRecaptcha
    >
      {formFieldsContentArea.map(item => (
        <Row key={item.id}>
          <Column width={item.columnWidth}>{mapToFormItem(item)}</Column>
        </Row>
      ))}
      <ErrorMessage />
      <SubmitButton>{submitLabel}</SubmitButton>
    </Form>
  );
};

export default FormBlock;
