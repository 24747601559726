import { offersAmount, offerWeek } from '../../lib/offerInfo';
import { offerRequest } from '../../lib/request';
import {
  TFetchAllOffers,
  TFetchOffersByIds,
  TOfferData
} from '../../types/offers/offers';

export const fetchAllOffers = ({ nextWeek, categories }: TFetchAllOffers) => {
  const res: Promise<TOfferData> = offerRequest(
    `/offers?size=${offersAmount}&${
      nextWeek ? offerWeek.next : offerWeek.current
    }=true${categories?.length ? `&category=${categories.join(',')}` : ''}`
  );
  return res;
};

export const fetchOffersByIds = ({
  ignoreStore,
  ximEntitiesList
}: TFetchOffersByIds) => {
  const res: Promise<TOfferData> = offerRequest(
    `/offers/${ximEntitiesList.join(',')}?${
      ignoreStore ? 'ignoreStore=true' : ''
    }`
  );
  return res;
};
