import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setVisibility } from '../../actions/shoppingOrder';
import { clearOverlay } from '../../actions/app';
import './RichText.scss';

class RichText extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.addEventListener('click', this.handleClick);
    }
  }

  componentWillUnmount() {
    if (this.ref.current) {
      this.ref.current.removeEventListener('click', this.handleClick);
    }
  }

  handleClick = event => {
    if (
      !!event.target.getAttribute('data-internal') &&
      event.target.getAttribute('data-internal') !== 'false' &&
      !event.target.getAttribute('target')
    ) {
      event.preventDefault();
      const { history } = this.props;
      history.push(event.target.getAttribute('href'));
      this.props.setVisibility(false);
      this.props.clearOverlay();
    }
  };

  render() {
    const { text, children } = this.props;

    return (
      <div className="c-richtext">
        {text && (
          <div
            className="c-richtext__inner"
            dangerouslySetInnerHTML={{ __html: text }}
            ref={this.ref}
          />
        )}

        {!text && children && children}
      </div>
    );
  }
}
RichText.propTypes = {
  setVisibility: PropTypes.func,
  clearOverlay: PropTypes.func,
  history: PropTypes.object,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
const mapDispatchToProps = {
  setVisibility,
  clearOverlay
};

export default connect(
  null,
  mapDispatchToProps
)(RichText);
