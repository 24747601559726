import React from 'react';
import RichText from '../../containers/RichText';
import { LazyProgressivePicture } from '../../components/ProgressivePicture';
import { LinkButton } from '../../components/Buttons';

import './TextWithImage.scss';
import { ITextWithImageProps } from '../../types/episerver/blocks/TextWithImage';

const TextWithImage : React.FC<ITextWithImageProps> = ({ image, headline, text, link, linkText }) => (
  <div className="b-text-with-image">
    <div className="b-text-with-image__column">
      {image && (
        <LazyProgressivePicture
          largeImage={image}
          maxWidth={{
            small: 767,
            large: 930
          }}
        />
      )}
    </div>
    <div className="b-text-with-image__column">
      <div className="b-text-with-image__text">
        {headline && (
          <h2 className="b-text-with-image__headline">{headline}</h2>
        )}
        {text && <RichText text={text} />}
        {link &&
          link.url && (
            <LinkButton primary link={link} style={{ alignSelf: 'center' }}>
              {linkText}
            </LinkButton>
          )}
      </div>
    </div>
  </div>
);

export default TextWithImage;
