import React from 'react';
import {
  RouteWithSubroutes,
  ProtectedRoute
} from '../../../components/Routing';
import { withErrorBoundary } from '../../../components/Error/ErrorBoundary';
import {
  withFlyoutPortal,
  withCloseableOverlay
} from '../../../components/Flyout/withFlyout';
import Flyout from '../../../components/Flyout';
import pages from '../../../pages';
import * as PageLayoutTypes from '../../../prop-types/PageTypePropType';
import { makeRegistry } from '../../../registry';
import { mapPageTypeToRouteType } from '../defaults';
import zip from 'lodash.zip';

const FlyoutWrapped = withFlyoutPortal(withCloseableOverlay(Flyout));
const Registry = makeRegistry(pages);

export const createComp = (route, options = {}) => {
  const { component, layout, isProtected, opts } = Registry.lookup(route.type);
  const props = {
    ...options,
    key: route.id,
    exact: typeof route.exact !== 'undefined' ? route.exact : true,
    path: route.path,
    component,
    layout,
    layoutProps: opts,
    subRoutes: route.subRoutes
  };
  return isProtected ? (
    <ProtectedRoute {...props} />
  ) : (
    <RouteWithSubroutes {...props} />
  );
};

const createFlyout = route => {
  const Component = withErrorBoundary(Registry.lookup(route.type).component);

  return {
    ...route,
    component: props => {
      const { close } = props;
      return (
        <FlyoutWrapped {...props} onClose={close}>
          <Component
            ownProps={props}
            isFlyout={true}
            pageLayout={PageLayoutTypes.FLYOUT}
          />
        </FlyoutWrapped>
      );
    }
  };
};

const createFlatRoutes = (routeList, opts) =>
  routeList.reduce(
    (acc, route) =>
      acc.concat(
        createComp(route, opts),
        route.subRoutes ? createFlatRoutes(route.subRoutes, opts) : []
      ),
    []
  );

// TODO duplicated in resolver
// TODO: We should pass opts from navigation to `route` so we can handle wildcard
// routes more dynamically. eg by setting a flag and route regexp in epi.

const createWilcardRoutes = routeList => {
  if (!routeList?.length) return [];
  return routeList
    .filter(
      ({ type }) =>
        (type.toLowerCase().includes('folder') &&
          !type.toLowerCase().includes('bag')) ||
        type === 'DeliveryDistrictListPage' ||
        type === 'OfferPage'
    )
    .map((route, i) => {
      const isOfferPage = route.type === 'OfferPage';
      const opts = {
        id: `${route.id}:${i}`,
        pageId: route.id,
        exact: false,
        path: mapPageTypeToRouteType[route.type].conditionalPattern,
        layoutProps: {
          hasSidebar: mapPageTypeToRouteType[route.type].hasSidebar
        },
        type: `${
          // @TODO might need to change in epi for this to work without this.
          isOfferPage
            ? 'OfferSinglePage'
            : route.type.replace('Folder', 'Single')
        }`,
        subRoutes: []
      };
      return [createComp(opts), createFlyout(opts)];
    });
};

export const defineRoutes = (routes = [], rootRoutes = [], opts = {}) => {
  const flatRoutes = createFlatRoutes(rootRoutes.concat(routes), opts);
  const [wildcardRoutes, flyoutRoutes] = zip(
    ...createWilcardRoutes(rootRoutes)
  );

  return {
    defaultRoutes: flatRoutes.concat(wildcardRoutes || []),
    flyoutRoutes: flyoutRoutes || []
  };
};

export default defineRoutes;
