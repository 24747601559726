import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAllOffers, fetchOffersByIds } from '../api/endpoints/offer';
import {
  TAggregationBucket,
  TFetchAllOffers,
  TFilters,
  TOffer
} from '../types/offers/offers';

type TGetAllOffers = TFetchAllOffers;

type TGetSingleOffer = {
  offerId: string;
  ignoreStore: boolean;
};

type TOffersState = {
  offers: TOffer[];
  categories?: TAggregationBucket[];
  offerTypes?: TAggregationBucket[];
  fetching: boolean;
  error: string | null;
  singleOfferFetching: boolean;
  singleOffer?: TOffer | null;
  filters?: TFilters;
};

const initialState: TOffersState = {
  offers: [],
  categories: [],
  offerTypes: [], //@TODO is this needed ?
  fetching: false,
  error: null,
  singleOffer: null,
  singleOfferFetching: false,
  filters: {
    nextWeek: false
  }
};

export const getOffers = createAsyncThunk(
  'offers/getOffers',
  async ({ nextWeek }: TGetAllOffers, thunkAPI) => {
    try {
      const response = await fetchAllOffers({ nextWeek });

      return {
        data: response.data,
        filters: {
          nextWeek
        }
      };
    } catch (error) {
      // @ts-ignore
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSingleOffer = createAsyncThunk(
  'offers/getSingleOffer',
  async ({ offerId, ignoreStore }: TGetSingleOffer, thunkAPI) => {
    try {
      if (offerId) {
        const response = await fetchOffersByIds({
          ximEntitiesList: [offerId],
          ignoreStore
        });

        return {
          offer: response.data.offers[0]
        };
      }
    } catch (error) {
      // @ts-ignore
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOffers.pending, state => {
      state.fetching = true;
    });

    builder.addCase(getOffers.fulfilled, (state, action) => {
      return {
        ...state,
        fetching: false,
        error: null,
        offers: action.payload?.data.offers ?? [],
        categories: action.payload?.data.aggregations?.category?.buckets,
        filters: action.payload?.filters
      };
    });

    builder.addCase(getOffers.rejected, (state, action) => {
      state.fetching = false;
      state.error = action.error.message || 'slice: offers/getOffers failed';
    });

    builder.addCase(getSingleOffer.pending, state => {
      state.singleOfferFetching = true;
      state.singleOffer = null;
    });

    builder.addCase(getSingleOffer.fulfilled, (state, action) => {
      return {
        ...state,
        singleOfferFetching: false,
        singleOffer: action.payload?.offer
      };
    });

    builder.addCase(getSingleOffer.rejected, (state, action) => {
      state.singleOfferFetching = false;
      state.error =
        action.error.message || 'slice: offers/getSingleOffer failed';
    });
  }
});

export const selectOffers = (state): TOffer[] => state.offers.offers;
export const selectODP = (state): TOffer => state.offers.singleOffer;
export const selectODPFetching = (state): boolean =>
  state.offers.singleOfferFetching;
export const selectOffersCategories = (state): TAggregationBucket[] =>
  state.offers.categories;
export const selectFetchingOffers = (state): boolean => state.offers.fetching;
export const selectErrorOffers = state => state.offers.error;
export const selectOfferFilters = (state): TFilters => state.offers.filters;

export const offersReducer = offersSlice.reducer;
