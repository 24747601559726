import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProductPlaceholder from '../assets/produkt-fallback.svg';
import { LazyProgressivePicture, ImageFitTypes } from '../ProgressivePicture';
import { Checkbox } from '../Form/FormItem/FormItems';
import Select from '../Select';
import { Button } from '../Buttons';
import { formatPriceWithDecimalsReduced } from '../../lib/price';
import {
  makeQuantityLabel,
  makeSidesLabel,
  shouldDisplayPrice
} from './catering-helpers';
import Availability from '../Availability';
import { getNextAvailabilityDateForStore } from '../../lib/availability/availability';

import cs from 'classnames';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { useDispatch, useSelector } from 'react-redux';
import * as ga4 from '@citygross/analytics';

import './CateringCard.scss';
import { addCateredMealToCart } from '../../actions/cart';

const CateringCard = props => {
  const {
    title,
    description,
    productImageUrl,
    variants,
    hasSides,
    sides,
    isBuffe,
    defaultVariant,
    minOrder,
    maxOrder,
    sendCateringItemAddTracking,
    setVariant,
    isAuthenticated,
    redirectToLogin,
    url,
    esalesClickTriggerOnAddingToCart,
    esalesClickTrigger,
    id,
    label,
    singularLabel,
    impressionListName,
    impressionListPosition,
    unit,
    onCarousel
  } = props;
  const storeNumber = useSelector(state => selectCurrentAssortment(state));
  const dispatch = useDispatch();
  const [{ choosenKey, choosenVariant }, setChoosenVariant] = useState({
    choosenKey: defaultVariant,
    choosenVariant: variants[defaultVariant][0].id
  });
  const matchedVariant =
    variants?.[choosenKey]?.find(x => x.id === choosenVariant) ||
    variants?.[choosenKey]?.[0];

  useEffect(() => {
    setChoosenVariant({
      choosenKey: defaultVariant,
      choosenVariant: variants[defaultVariant][0].id
    });
  }, [defaultVariant]);

  const [selectedVariant, setSelectedVariant] = useState(
    makeQuantityLabel(variants[defaultVariant], label)[0]
  );

  const price = useMemo(() => {
    if (
      selectedVariant &&
      selectedVariant.prices?.storeNumber === storeNumber
    ) {
      return formatPriceWithDecimalsReduced(
        selectedVariant.prices.currentPrice
      );
    } else {
      return formatPriceWithDecimalsReduced(
        selectedVariant?.defaultPrice?.currentPrice?.price
      );
    }
  }, [storeNumber, selectedVariant, variants, defaultVariant]);

  const sortQuantities = (a, b) => (a.quantityFrom > b.quantityFrom ? 1 : -1);
  const sorted = variants[defaultVariant].sort(sortQuantities);

  const [quantity, setQuantity] = useState(
    isBuffe
      ? {
          quantityFrom: minOrder
        }
      : {
          quantityFrom: sorted[0].quantityFrom,
          quantityTo: sorted[0].quantityTo
        }
  );
  const [choosenSide, setSide] = useState(null);

  const [sidesHasError, setSideHasError] = useState({
    showMessage: false,
    highlightError: false
  });

  const [hasValidationError, setValidationError] = useState({
    show: false,
    message: ''
  });

  const nextAvailabiltyDate =
    storeNumber &&
    getNextAvailabilityDateForStore(
      selectedVariant.availability || [],
      storeNumber
    );
  const shouldShowAvailability = nextAvailabiltyDate;
  const hasAvailability =
    selectedVariant.availability?.length &&
    selectedVariant?.availability?.find?.(x => x.storeNumber === storeNumber);
  const useMakeOrderLine = () => {
    return {
      plu: selectedVariant.gtin.substr(4, 4),
      gtin: selectedVariant.gtin,
      id: selectedVariant.id,
      parentId: id,
      variant: selectedVariant.name,
      name: title,
      quantity,
      originalPrice: selectedVariant.prices.currentPrice,
      totalPrice:
        minOrder || maxOrder
          ? selectedVariant.prices.currentPrice * quantity.quantityFrom
          : selectedVariant.prices.currentPrice,
      price: formatPriceWithDecimalsReduced(
        minOrder || maxOrder
          ? selectedVariant.prices.currentPrice * quantity.quantityFrom
          : selectedVariant.prices.currentPrice
      ),
      side: hasSides ? choosenSide.label : '',
      productImageUrl,
      sides: sides,
      sideId: hasSides ? choosenSide.id : '',
      image: productImageUrl,
      unit: unit,
      url: url
    };
  };

  const filterOutSameGtin = variants => {
    const uniqueVariants = [];
    variants?.forEach(v => {
      if (!uniqueVariants.find(unique => unique?.gtin === v?.gtin)) {
        uniqueVariants.push(v);
      }
    });
    return uniqueVariants;
  };

  const priceChecker = useMemo(() => {
    return shouldDisplayPrice(price);
  }, [price]);

  if (priceChecker) {
    return null;
  }
  return (
    <div
      className={cs('l-column-15_xs-60_sm-30_md-20_lg-15_xlg-10', {
        'c-carousel-card': onCarousel
      })}
    >
      <div className={'c-catering-card'} data-productid={id}>
        <Link
          draggable={!onCarousel}
          to={{
            pathname: url,
            state: {
              flyout: true,
              title: ' ',
              nopadding: true,
              tight: false
            }
          }}
          onClick={e => {
            esalesClickTrigger(e);
            if (impressionListName && impressionListPosition) {
              const productCategory =
                url?.substring(0, url?.lastIndexOf('/') + 1) || undefined;
              const isCake =
                productCategory?.includes('tartor') &&
                !productCategory?.includes('personer');

              try {
                const ga4SelectItem = {
                  item_list_id: impressionListName,
                  item_list_name: impressionListName,
                  items: [
                    {
                      item_id: selectedVariant?.gtin,
                      item_name: `${title} ${
                        isCake
                          ? `- ${selectedVariant?.quantityFrom ??
                              selectedVariant?.quantityFrom} portioner`
                          : ''
                      }`,
                      index: impressionListPosition,
                      item_brand: 'City Gross',
                      item_category:
                        url?.substring(0, url.lastIndexOf('/') + 1) || '',
                      item_list_name: impressionListName,
                      item_list_id: impressionListName,

                      item_variant: defaultVariant,
                      price: selectedVariant?.prices?.ordinaryPrice,
                      discount: 0,
                      quantity: minOrder || 1
                    }
                  ]
                };

                ga4.selectItem(ga4SelectItem);
              } catch (error) {
                console.error(error);
              }
            }
          }}
        >
          <div className={'c-catering-card__image'}>
            <LazyProgressivePicture
              largeImage={{
                url: productImageUrl,
                width: 300,
                height: 300
              }}
              fitType={ImageFitTypes.Contain}
              inheritHeight
              showThumb={false}
              maxWidth={{
                large: 300,
                small: 300
              }}
              placeholder={{
                image: ProductPlaceholder,
                bgColor: 'transparent'
              }}
            />
          </div>
          <div className={'c-catering-card__title'}>
            <h3>{title}</h3>
          </div>
          <div className={'c-catering-card__description mb-5'}>
            <p>{description}</p>
          </div>
        </Link>

        <div className={'c-catering-card__specials mb-20'}>
          {Object.keys(variants).map(variantKey => {
            const variantHasChoosen =
              choosenVariant &&
              variants[variantKey]?.some(
                variant => variant?.id === choosenVariant
              );
            return Object.keys(variants).length === 1 &&
              variantKey.toLowerCase() === 'standard' ? null : (
              <Checkbox
                key={variants[variantKey][0].id}
                isChecked={variantHasChoosen}
                id={variants[variantKey][0].id}
                label={variants[variantKey][0].name}
                className={'mt-10'}
                onChange={() => {
                  if (variantHasChoosen) {
                    return;
                  }
                  const newSelectedVariant = variants[variantKey]?.find(
                    chosen =>
                      chosen.quantityFrom === matchedVariant?.quantityFrom
                  );
                  setChoosenVariant({
                    choosenKey: variantKey,
                    choosenVariant:
                      newSelectedVariant?.id ?? variants[variantKey][0].id
                  });
                  const quantityLabels = makeQuantityLabel(
                    variants[variantKey]
                  );
                  setSelectedVariant(
                    quantityLabels?.find(
                      variant =>
                        variant.quantityFrom === matchedVariant?.quantityFrom
                    ) ?? quantityLabels[0],
                    label
                  );

                  setVariant(variantKey, selectedVariant.id);
                  if (!isBuffe) {
                    setQuantity(
                      newSelectedVariant ??
                        variants[variantKey].sort(sortQuantities)[0]
                    );
                  }
                }}
              />
            );
          })}
        </div>

        {hasSides && (
          <div className={'c-catering-card__sides'}>
            {sidesHasError.showMessage && (
              <div className={'c-catering-card__error-message'}>
                <p>Vänligen välj ett tillbehör</p>
              </div>
            )}
            <Select
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              highlight={sidesHasError?.highlightError}
              onChange={sideId => {
                if (sideId.toLowerCase() !== 'välj tillbehör') {
                  setSide(() => {
                    const choosenSide = makeSidesLabel(sides).find(
                      side => side.value === sideId
                    );
                    return choosenSide;
                  });
                  setSideHasError({
                    showMessage: false,
                    highlightError: false
                  });
                } else {
                  setSide(null);
                  setSideHasError({
                    showMessage: true,
                    highlightError: false
                  });
                }
              }}
              placeholder={'Välj tillbehör'}
              options={makeSidesLabel(sides)}
            />
          </div>
        )}
        <div
          className={cs('c-catering-card__lower-section', {
            'c-catering-card__lower-section-with-sides': hasSides
          })}
        >
          {hasValidationError.show && (
            <div className={'c-catering-card__error-message'}>
              <p>{hasValidationError.message}</p>
            </div>
          )}
          <div className={'c-catering-card__price-section mb-20 u-my-20'}>
            <div className={'c-catering-card__price'}>
              {price?.value && (
                <p>
                  {price.decimalCount > 0
                    ? `${price.value},${price.decimal}`
                    : `${price.value}`}{' '}
                  kr{isBuffe ? `/${singularLabel}` : ''}
                </p>
              )}
            </div>
            <div className={'c-catering-card__select-amount'}>
              {isBuffe ? (
                <>
                  <input
                    className={'c-catering-card__amount-input'}
                    type={'number'}
                    value={quantity.quantityFrom}
                    min={minOrder}
                    max={maxOrder}
                    onFocus={() =>
                      setValidationError({
                        show: false,
                        message: `Minsta beställning ${minOrder} ${label}`
                      })
                    }
                    onBlur={({ target }) => {
                      if (
                        !target.value ||
                        target.value < minOrder ||
                        target.value > maxOrder
                      ) {
                        setValidationError(
                          target < minOrder
                            ? {
                                show: true,
                                message: `Minsta beställning är ${minOrder} ${label}`
                              }
                            : {
                                show: true,
                                message: `Störta beställning är ${maxOrder} ${label}`
                              }
                        );
                        setQuantity(() => {
                          setValidationError(false);
                          return { quantityFrom: minOrder };
                        });
                      }
                    }}
                    onChange={e => {
                      const target = e.target.value;

                      if (!target || target < minOrder || target > maxOrder) {
                        setValidationError(
                          !target || target < minOrder
                            ? {
                                show: true,
                                message: `Minsta beställning är ${minOrder} ${label}`
                              }
                            : {
                                show: true,
                                message: `Största beställning är ${maxOrder} ${label}`
                              }
                        );
                      } else {
                        setValidationError({ show: false, message: '' });
                      }

                      setQuantity({
                        quantityFrom: parseInt(target)
                      });
                    }}
                  />{' '}
                  <span className={'c-catering-card__portions'}>{label}</span>
                </>
              ) : (
                <Select
                  defaultValue={selectedVariant.id}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onChange={productId =>
                    setSelectedVariant(() => {
                      const selectedVariant = makeQuantityLabel(
                        filterOutSameGtin(variants[choosenKey]),
                        label
                      ).filter(product => product.id === productId);
                      setChoosenVariant({
                        choosenKey,
                        choosenVariant: productId
                      });
                      setQuantity({
                        quantityFrom: selectedVariant[0].quantityFrom,
                        quantityTo: selectedVariant[0].quantityTo
                      });

                      setVariant(choosenKey, selectedVariant[0].id);
                      return selectedVariant[0];
                    })
                  }
                  options={makeQuantityLabel(
                    filterOutSameGtin(variants[choosenKey]),
                    label
                  )}
                />
              )}
            </div>
          </div>

          <div>
            {shouldShowAvailability && (
              <div>
                <Availability
                  availability={selectedVariant?.availability || []}
                />
              </div>
            )}
          </div>
          <div className={'c-catering-card__add-to-form-section'}>
            <Button
              fullwidth
              disabled={!hasAvailability || Boolean(shouldShowAvailability)}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                // quantity not met
                if (quantity < minOrder) {
                  setValidationError({
                    show: true,
                    message: `Minsta beställning är ${minOrder} ${label}`
                  });
                  return;
                }
                if (quantity > maxOrder) {
                  setValidationError({
                    show: true,
                    message: `Störta beställning är ${maxOrder} ${label}`
                  });
                  return;
                }
                // no side selected
                if (hasSides && !choosenSide) {
                  setSideHasError({
                    showMessage: true,
                    highlightError: true
                  });
                  return;
                }
                // is valid add to cart
                esalesClickTriggerOnAddingToCart();
                if (isAuthenticated) {
                  const itemToAdd = useMakeOrderLine();

                  sendCateringItemAddTracking(itemToAdd);
                  dispatch(addCateredMealToCart(itemToAdd));
                } else {
                  redirectToLogin();
                }
              }}
            >
              {price?.value
                ? hasSides && !choosenSide
                  ? 'Välj tillbehör'
                  : 'Lägg till'
                : 'Inte tillgänglig'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
CateringCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  productImageUrl: PropTypes.string,
  variants: PropTypes.object,
  sides: PropTypes.array,
  price: PropTypes.number,
  hasVariants: PropTypes.bool,
  hasSides: PropTypes.bool,
  isBuffe: PropTypes.bool,
  defaultVariant: PropTypes.string,
  minOrder: PropTypes.number,
  maxOrder: PropTypes.number,
  setVariant: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  redirectToLogin: PropTypes.func,
  url: PropTypes.string,
  esalesClickTriggerOnAddingToCart: PropTypes.func,
  esalesClickTrigger: PropTypes.func,
  onCarousel: PropTypes.bool
};
export default CateringCard;
