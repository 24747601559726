import React, { useState } from 'react';
import { FavoriteButton } from '../../../components/Buttons';
import PriceTag from '../../../components/PriceTag';
import Image from '../../../components/Image';
import BuyButton from '../../../components/BuyButton';
import Availability from '../../../components/Availability';
import { MixCampaign } from '../../../components/MixCampaign';
import './ProductSingleDetails.scss';
import { setImage } from '../../../lib/product';
import { IExtendedProduct } from '../../../types/storefront/product';
import { PriceRuleHelper } from '../../../lib/price';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import {
  productFavoritesSelector,
  favoriteProductsLoadingSelector
} from '../../../selectors/product/productSelectors';
import { removeFavorite, setProductFavorite } from '../../../actions/auth';
import WarningLabel from '../../../components/WarningLabel';
import { isLimitedStock } from '../../../lib/limitedStock';
import { LIMITED_DEAL_TAG } from '../../../components/ProductCard/ProductCard';
import { selectCurrentAssortment } from '../../../selectors/assortments';
import ProductOfferEndDate from './ProductOfferEndDate';
import ProductPricePromotions from './ProductPricePromotions';
import ProductPrices from './ProductPrices';
import ProductPricePromotionsDisclaimer from './ProductPricePromotionsDisclaimer';
import ProductMarkings from './ProductMarkings';
import ProductMaxAmount from './ProductMaxAmount';
import Meta from './Meta';

interface IProductSingleDetails {
  esalesClickTriggerOnAddingToCart: Function;
  isAuthenticated: boolean;
  product: IExtendedProduct;
}

const ProductSingleDetails: React.FC<IProductSingleDetails> = ({
  esalesClickTriggerOnAddingToCart,
  product
}) => {
  const dispatch = useDispatch();
  const favorite = useSelector((state: AppState) =>
    productFavoritesSelector(state).find(x => x.itemNo === product.id)
  );
  const isFavoriteLoading = useSelector((state: AppState) =>
    Boolean(favoriteProductsLoadingSelector(state).find(x => x === product.id))
  );
  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  const [showMax, setShowMax] = useState<boolean>(false);

  const priceValidUntil = date.toLocaleDateString('sv-SE');
  const image = setImage(product.images, 0);
  const formattedPrice = PriceRuleHelper(product.price);
  const isLimited = isLimitedStock(product);

  const hasLimitedDeal = !!product?.tags?.find(
    tag =>
      tag.name === LIMITED_DEAL_TAG.name &&
      tag.namespace === LIMITED_DEAL_TAG.namespace
  );

  return (
    <div
      className="details-block"
      data-product-id={product.gtin}
      itemType={'http://schema.org/Product'}
      itemScope
    >
      <meta itemProp="productID" content={product.gtin} />
      <meta itemProp="sku" content={product.gtin} />
      <meta itemProp="brand" content={product.brand || ''} />
      <meta itemProp="url" content={'https://www.citygross.se' + product.url} />
      {image && (
        <link itemProp="image" href={'https://www.citygross.se' + image.url} />
      )}
      <meta itemProp="name" content={product.name} />
      <meta
        itemProp="description"
        content={product.description || product.brand || product.name}
      />

      <div className={'left'}>
        <Meta product={product} />
        <div
          className="shop"
          itemProp="offers"
          itemType="http://schema.org/Offer"
          itemScope
        >
          <meta
            itemProp="availability"
            content={
              !product.availability ||
              product.availability.length === 0 ||
              !product.price
                ? 'https://schema.org/OutOfStock'
                : 'https://schema.org/InStock'
            }
          />
          <meta itemProp="priceValidUntil" content={priceValidUntil} />
          <meta
            itemProp="price"
            content={`${product.defaultPrice?.ordinaryPrice.price || 0}`}
          />

          <meta itemProp="priceCurrency" content="SEK" />

          <div className={'shop__price-wrapper'}>
            {product.price?.promotions2 &&
              product.price?.promotions2[0]?.minAmount && (
                <MixCampaign
                  detailPage
                  promotion={{
                    label: `Handla andra varor för ${product.price.promotions2[0].minAmount}:-*`
                  }}
                />
              )}
            {product.price && (
              <PriceTag
                forceLeftAlign={true}
                hasLimitedDeal={hasLimitedDeal}
                price={product.price}
                unit={product.unit}
                noPadding={false}
                pant={
                  product.returnablePackageDepositInformation?.description ===
                  'Pant'
                }
              />
            )}
          </div>

          {isLimited && (
            <div className={'mb-10'}>
              <WarningLabel
                label={'Begr. tillgänglighet'}
                extraMargin={Boolean(
                  product.price?.hasDiscount && formattedPrice?.saveLabel
                )}
              />
            </div>
          )}
          <div className={'push-to-bottom'}>
            <div className={'price-comparison'}>
              <ProductPrices
                product={product}
                formattedPrice={formattedPrice}
              />
            </div>
            <ProductPricePromotions
              product={product}
              formattedPrice={formattedPrice}
            />

            <ProductMaxAmount showMax={showMax} />
            <ProductOfferEndDate product={product} />

            <div className="actions mt-10">
              <BuyButton
                product={product}
                fullWidth={false}
                primaryButton={true}
                noMarginQty={true}
                setShowMax={setShowMax}
                esalesClickTriggerOnAddingToCart={
                  esalesClickTriggerOnAddingToCart
                }
              />
              <FavoriteButton
                light={true}
                active={Boolean(favorite)}
                loading={Boolean(isFavoriteLoading)}
                handleClick={
                  favorite
                    ? () => dispatch(removeFavorite(favorite))
                    : () =>
                        dispatch(
                          setProductFavorite(product.id, {
                            name: product.name,
                            value: Number(product.price?.currentPrice?.price)
                          })
                        )
                }
              />
            </div>

            {storeNumber && (
              <Availability availability={product.availability || []} />
            )}
            <ProductPricePromotionsDisclaimer product={product} />
          </div>
        </div>
      </div>
      <div className={'right'}>
        <ProductMarkings product={product} />
        <div className="image">
          <Image src={image.url + '?w=300'} alt={image.alt} title={image.alt} />
        </div>
      </div>
    </div>
  );
};

export default ProductSingleDetails;
