const DAYS_BEFORE_CAMPAIGN_STARTS = 7;

export const findAvailabilityByStore = (availabilityArray, store) => {
  return availabilityArray.find(
    availability => availability.storeNumber === store
  );
};

export const setAvailabilityThresholdByDate = date => {
  const availabilityDate = new Date(date);
  availabilityDate.setDate(date.getDate() - DAYS_BEFORE_CAMPAIGN_STARTS);

  return availabilityDate;
};
