import { Product, PriceDetail } from "../types/xim/product";
import { IExtendedProductPrice } from "../types/storefront/product";

export const formatPriceWithDecimalsReduced = (price? : number | null) => {
  if ((price !== 0 && !price) || isNaN(price)) {
    return null
  }
  const priceArr = price && price.toString().split('.');
  let decimalCount = priceArr && priceArr[1] ? priceArr[1].length : 0;
  //Remove decimals
  const priceInteger = Math.trunc(price);

  // Avoid rounding errors, since JS can't do math
  let priceDecimals = (price - priceInteger).toFixed(2).toString();
  const decimalsArr = priceDecimals.split('.');
  priceDecimals = decimalsArr.length > 1 ? decimalsArr[1] : priceDecimals;

  // Pad with leading 0 if single number
  const priceDecimalsReduced =
    priceDecimals.length < 2 ? '0' + priceDecimals : priceDecimals;

  return {
    value: priceInteger,
    decimal: priceDecimalsReduced,
    decimalCount: decimalCount,
    fullValue: priceInteger + ',' + priceDecimalsReduced,
  };
};

export const PriceUnit = {
  kilogram : 'Kilogram'
}


export const calculateSellingUnitOfMeasure = (product: Product) : string => {
  const isBulkProduct = product.sellingUnitOfMeasure === 2 || product.sellingUnitOfMeasure === PriceUnit.kilogram;
  const hasReturnablePackageDeposit =
    product.returnablePackageDepositInformation &&
    product.returnablePackageDepositInformation.description === 'Pant';

  const unit = isBulkProduct
    ? 'kg'
    : hasReturnablePackageDeposit
    ? '+pant'
    : 'st';

    return unit;
}

interface PriceRule {
  currentPrice: PriceDetail | null | undefined,
  ordinaryPrice: PriceDetail | null,
  saveLabel: string | null,
  formattedCurrentPrice: {
    value: number,
    decimal: string,
    decimalCount: number
  } | null,
  formattedOrdinaryPrice: {
    value: number,
    decimal: string,
    decimalCount: number
  } | null,
  currentComparisonPrice: string,
  ordinaryComparisonPrice: string,
  validPromotion?: boolean
}

type TFormattedPrice = {
  value: number;
  decimal: string;
  decimalCount: number;
} | null;

export const checkPromotionPrice = (price?: IExtendedProductPrice | null)  => { 
  
  const mixPrice = price?.promotions2;
  let validPromotion = false;
  let formattedPrice: TFormattedPrice = null;
  if (
    mixPrice?.[0]?.priceDetails?.comparativePrice &&
    price?.currentPrice?.comparativePrice
  ) {
    validPromotion =
      mixPrice?.[0]?.priceDetails?.comparativePrice <=
      price?.currentPrice?.comparativePrice;
  } else if (
    mixPrice?.[0]?.priceDetails?.price &&
    price?.currentPrice?.price
  ) {
    validPromotion =
      mixPrice?.[0]?.priceDetails?.price <= price?.currentPrice?.price;
  }

  formattedPrice =
    mixPrice && mixPrice.length > 0 && validPromotion
      ? formatPriceWithDecimalsReduced(mixPrice[0]?.value)
      : formatPriceWithDecimalsReduced(price?.currentPrice?.price);

  const mixMinQuantity =
  validPromotion && 
    mixPrice &&
    mixPrice[0]?.minQuantity &&
    parseInt(mixPrice[0]?.type) === 1 &&
    mixPrice[0]?.minQuantity > 1 &&
    mixPrice[0]?.minQuantity;
  return {
    formattedPrice,
    validPromotion,
    mixObject: validPromotion && mixPrice,
    isMix: mixPrice && mixPrice[0]?.type,
    mixMinQuantity,
    isExtraPrice: price?.hasDiscount && mixPrice && mixPrice?.length > 0,
    isOnlyForMembers:
      (price?.promotions2 && price?.promotions2[0]?.membersOnly) ||
      Boolean(price?.memberPrice)
  };

 }
export const PriceRuleHelper = (price?: IExtendedProductPrice | null) : PriceRule | null => {
  if(!price) {
    return null
  }

  // TODO Move up to selector
  const mix = price?.promotions2 && price?.promotions2[0];

  let validPromotion = checkPromotionPrice(price)?.validPromotion;
  

  const selectedPrice = ((price.memberPrice || mix)  && validPromotion)? mix?.priceDetails : price?.currentPrice;
  const formattedCurrentPrice = ( mix && validPromotion ) ? formatPriceWithDecimalsReduced(mix.value) : formatPriceWithDecimalsReduced(selectedPrice?.price);
  const formattedOrdinaryPrice = formatPriceWithDecimalsReduced(price?.ordinaryPrice?.price);
  const currentComparisonPrice = formatPriceWithDecimalsReduced(price?.currentPrice?.comparativePrice);
  const ordinaryComparisonPrice = formatPriceWithDecimalsReduced(price?.ordinaryPrice?.comparativePrice);

  const savePrice = selectedPrice && formatPriceWithDecimalsReduced(
    price?.ordinaryPrice?.price - ((mix  && validPromotion )? Number(mix?.value) : selectedPrice?.price)
  );

  let saveLabel : string | null = null;

  if(savePrice && validPromotion) {
    saveLabel = savePrice.value >= 5 || (savePrice.value >= 4 && Number(savePrice.decimal) >= 95)
    ? `${savePrice.value},${savePrice.decimal}`
    : null;
  }

  return {
    currentPrice: selectedPrice,
    ordinaryPrice: price?.ordinaryPrice,
    saveLabel,
    validPromotion,
    formattedCurrentPrice,
    formattedOrdinaryPrice,
    currentComparisonPrice: `${currentComparisonPrice?.value + ',' + currentComparisonPrice?.decimal}`,
    ordinaryComparisonPrice: `${ordinaryComparisonPrice?.value + ',' + ordinaryComparisonPrice?.decimal}`,
  }
}