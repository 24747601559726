import { ProductAvailability } from '../../types/xim/product';
import {
  findAvailabilityByStore,
  setAvailabilityThresholdByDate
} from './availabilityHelpers';

export const getNextAvailabilityDateForStore = (
  availabilityArray: ProductAvailability[],
  store: number | string | null,
  compareDate: Date = new Date()
) => {
  if (!availabilityArray || availabilityArray.length === 0) {
    return null;
  }

  const availability = findAvailabilityByStore(availabilityArray, store);

  if (!availability) {
    return null;
  }

  const { availableFrom, availableTo, stockStatus } = availability;

  const date = compareDate; // we set this to +1 to take delivery time into account
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 1);

  const currentDate = new Date(); // set to track availability
  currentDate.setHours(0, 0, 0, 0);

  const availableFromDate = availableFrom ? new Date(availableFrom) : null;
  const availableToDate = availableTo ? new Date(availableTo) : null;
  availableFromDate?.setHours(0, 0, 0, 0);
  availableToDate?.setHours(0, 0, 0, 0);

  const availabilityThresholdDate = availableToDate
    ? setAvailabilityThresholdByDate(availableToDate)
    : null;

  if (!availableFromDate && !availableToDate) return null;

  // if it's an upcoming campaign we display its availability date
  if (availableFromDate && date < availableFromDate) {
    return { availableFrom };
  }

  // if campaign is ongoing we don't show anything
  if (availableFromDate && availableFromDate <= currentDate) {
    return null;
  }

  // if availableToDate and campaign is available within a 7 day threshold from today's date
  // we display when the campaign starts
  if (
    availableToDate &&
    availabilityThresholdDate &&
    currentDate >= availabilityThresholdDate &&
    currentDate <= availableToDate
  ) {
    return { availableTo };
  }

  // if availability expired
  if (availableToDate && currentDate > availableToDate) {
    return { expired: true };
  }

  if (stockStatus === 2 && availableFrom) {
    return { availableFrom };
  }

  return null;
};
