import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import FavoriteProducts from '../../modules/FavoriteProducts';
import FavoriteRecipes from '../../modules/FavoriteRecipes';
import RichText from '../../containers/RichText';
import { IFavouritesBlockProps } from '../../types/episerver/blocks/FavouritesBlock';

interface IProps extends IFavouritesBlockProps {
  isAuthenticated: boolean
}

const FavouritesBlock : React.FC<IProps> = ({
  favouritesType,
  notLoggedInText,
  isAuthenticated = false
}) => {
  if (!isAuthenticated) {
    return <RichText text={notLoggedInText} />;
  }
  if (favouritesType === 'recipes') {
    return <FavoriteRecipes />;
  }
  if (favouritesType === 'products') {
    return <FavoriteProducts />;
  }
  return null;
};

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FavouritesBlock)
);
